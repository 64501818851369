<template>
  <div class="home">
    <div class="photo"></div>
    <div class="main">
      <!-- <div class="text">一个考下来每月能领取¥{{ objData.money }}的证</div> -->
      <div class="text">高中及中专以上即可报考</div>
    </div>

    <div class="countdown" @click="toProgram">
      <!-- <a
        href="weixin://dl/business/?appid=wxf544461d5d1a67b7&path=pages/collect/index"
        >跳转到目标小程序</a
      > -->

      微信领取报考资格
      <span v-if="isShowCountdown">({{ objData.time }})</span>
    </div>
    <div class="number">
      已有<span class="numbers">{{ objData.numbers }}</span
      >名同学报考成功
    </div>
    <ul class="avatar">
      <li v-for="(item, index) in pArr" :key="index">
        <img :src="require(`@/assets/images/touxiang/${item}`)" alt="" />
      </li>
    </ul>
    <van-popup v-model="isShow">
      <div class="dialog">
        <div class="dialog-button" @click="toFollow()">点击前往微信关注</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getData, getWxurl } from "@/api/home.js";
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      objData: { money: 200, time: 10, numbers: 31000 },
      timer: null, //定时器
      isShowCountdown: true, //是否显示倒计时
      isShow: false, //是否显示弹窗
      wxUrl: "",
      clickid: null,
      pArr: [
        "02bfaed56378f9842efc5e73c00548d0.jpeg",
        "9ce2e003970c4eda0c5762c1259b3674.jpeg",
        "19af882f6be3c7d47555ff06b1a1f5e6.png",
        "256a978851926f40feab04308d9df1a1.jpeg",
        "664154965beda99f68452c1112622bad.jpeg",
        "b07867bb3f53af2f422febb5b4ecf41c.jpeg",
        "c91ad66557a305591384fb06a7ecdda4.jpeg",
        "cbdba8d9e897a5cbdcd54075dda46291.jpeg",
        "ede4e402383b5bdaeac93ade0a036bca.jpeg",
      ],
    };
  },
  created() {
    const params = new URLSearchParams(window.location.search);
    const clickId = params.get("clickid");
    this.clickid = clickId;
    console.log("clickId", clickId);
    //设置倒计时
    this.timer = setInterval(() => {
      this.objData.time--;
      if (this.objData.time <= 0) {
        clearInterval(this.timer);
        this.isShowCountdown = false;
      }
    }, 1000);
    //设置报考人数
    let currentTime = new Date().getTime();
    let specificDate = new Date("2024-04-08T12:00:00").getTime();
    let addTime = Math.floor((currentTime - specificDate) / (1000 * 60 * 2.4));
    this.objData.numbers = Number(this.objData.numbers) + addTime;
    //获取数据
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
    // this.getData();
  },
  mounted() {},
  beforeDestroy() {
    clearInterval(this.timer);
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
  },
  methods: {
    showPopup() {
      this.isShow = true;
    },
    // 后台切换调用函数
    handleVisibilityChange() {
      if (document.visibilityState !== "hidden") {
        this.isShow = true; // 页面切换到前台时显示元素
        console.log("前台", this.isShow);
      }
    },
    //跳转到微信小程序(去填信息)
    toProgram() {
      const clickid = "clickid=" + this.clickid;
      // const clickid = "clickid=EPHk9cX3pv4CGJax4ZENKI7w4MDev_4C";
      console.log("clickid---", clickid);
      this.toMiniProgram("/pages/home/home", clickid);
    },
    //跳转到微信小程序(去关注公众号)
    toFollow() {
      this.toMiniProgram("/pages/webview/webview");
    },
    //获取小程序路径
    toMiniProgram(url, id) {
      const params = {
        path: url,
      };
      if (id) {
        params.query = id;
        // params.envVersion="trial";
      }
      getWxurl(params)
        .then((res) => {
          if (res.code === 10000) {
            let data = JSON.parse(res.data);
            console.log(data);
            window.location.href = data.openlink;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //获取数据请求
    getData() {
      console.log("111");
      getData()
        .then((res) => {
          if (res.code === 10000) {
            console.log(" res: ", res);
            this.objData = JSON.parse(JSON.stringify(res.data[0]));
            //设置倒计时
            this.timer = setInterval(() => {
              this.objData.time--;
              if (this.objData.time <= 0) {
                clearInterval(this.timer);
                this.isShowCountdown = false;
              }
            }, 1000);
            //设置报考人数
            let currentTime = new Date().getTime();
            let specificDate = new Date("2024-03-18T12:00:00").getTime();
            let addTime = Math.floor(
              (currentTime - specificDate) / (1000 * 60 * 2.4)
            );
            // console.log(currentTime);
            // console.log(specificDate);
            // console.log(addTime);
            this.objData.numbers = Number(this.objData.numbers) + addTime;
          }
        })
        .catch((err) => {
          console.log(" err: ", err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  height: 100vh;
  width: 100%;
  font-size: 14px;
  background-image: url("~@/assets/images/wecaht/beijing1111.png");
  background-size: cover;
  //background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  padding-bottom: 161%;
  letter-spacing: 1.5px;
  box-sizing: border-box;
  overflow: hidden;
  .photo {
    height: 1.12rem;
    margin-top: 0.25rem;
    background-image: url("~@/assets/images/wecaht/tu2.png");
    background-size: contain;
    //background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .main {
    // position: absolute;
    // top: 19vh;
    // left: 50%;
    // transform: translate(-50%);
    // width: 326px;
    // height: 294px;
    width: 3.26rem;
    height: 2.94rem;
    background-image: url("~@/assets/images/wecaht/kuang1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    margin: -0.15rem auto 0.3rem;

    .text {
      position: absolute;
      top: 2.45rem;
      left: 50%;
      transform: translate(-50%);
      color: #fff;
      white-space: nowrap;
      font-size: 0.12rem;
    }
  }
  .countdown {
    margin: 0 auto;
    width: 3.12rem;
    height: 0.45rem;
    background-image: url("~@/assets/images/wecaht/6ca035ea5a11beb68c9fc092ad978a3.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    line-height: 0.45rem;
    text-align: center;
    font-weight: bold;
  }
  .number {
    margin: 0.2rem auto;
    color: #fff;
    white-space: nowrap;
    letter-spacing: 2px;
    .numbers {
      font-size: 0.16rem;
      color: #ffad77;
      text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white,
        1px 1px 0 white;
    }
  }
  .avatar {
    margin: 0 auto;
    width: 2.9rem;
    height: 0.3rem;
    display: flex;
    justify-content: space-between;
    li {
      width: 0.29rem;
      height: 100%;
      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
    }
  }
  .dialog {
    position: relative;
    width: 3.19rem;
    height: 3.56rem;
    background-image: url("~@/assets/images/wecaht/8-1@3x.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    .dialog-button {
      position: absolute;
      top: 2.5rem;
      left: 50%;
      transform: translate(-50%);
      width: 2.62rem;
      height: 0.45rem;
      background-color: #0ab465;
      border-radius: 0.3rem;
      line-height: 0.45rem;
      text-align: center;
      color: #fff;
      font-size: 0.16rem;
    }
  }
  .van-popup {
    background-color: rgba(0, 0, 0, 0);
  }
}
</style>
