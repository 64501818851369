import axios from "axios";
import store from "@/store";

const requests = axios.create({
  baseURL: "/qihanshegong/api/mk",
  timeout: 50000,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    "Cache-Control": "no-cache", // 防止IE浏览器缓存
    // "Referer": "https://dongdong-service.oss-cn-beijing.aliyuncs.com" 
  },
});

// 请求拦截器：
requests.interceptors.request.use(
  (config) => {
    store.commit("showLoading");
    return config;
  },
  (error) => {
    store.commit("hideLoading");
    Promise.reject(error);
  }
);

// 响应拦截器，即异常处理：
requests.interceptors.response.use(
  (response) => {
    store.commit("hideLoading");
    return response.data;
  },
  (err) => {
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = "请求出错";
          break;
        case 401:
          err.message = "授权失败";
          break;
        case 403:
          err.message = "拒绝访问";
          break;
        case 404:
          err.message = "请求错误,未找到该资源";
          break;
        case 500:
          err.message = "服务器端出错";
          break;
      }
    } else {
      err.message = "连接服务器失败";
    }
    alert(err.message);
    store.commit("hideLoading");
    return Promise.reject(err.response);
  }
);

export default requests;
