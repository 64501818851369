import Vue from "vue";
import {
  Loading,
  Popup,
  CountDown,
  Tab,
  Tabs,
  Sidebar,
  SidebarItem,
  Col,
  Row,
  Toast,
  Icon 
} from "vant";

// Vue.use(Button);
// Vue.use(Dialog);
Vue.use(Loading);
Vue.use(Popup);
Vue.use(CountDown); // 倒计时
Vue.use(Toast);

Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Sidebar);
Vue.use(SidebarItem);
Vue.use(Col);
Vue.use(Row);
Vue.use(Icon);
