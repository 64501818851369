<template>
  <div class="tabs">
    <div class="option">
      <!-- <div
        class="tab"
        v-for="tab in tabList"
        :key="tab.id"
        @click="selectTab(tab.id)"
        :class="{ active: tab.id === activeOption }"
      >
        {{ tab.name }}
      </div> -->
    </div>
    <van-row class="row">
      <van-col span="9" class="row-left">
        <van-sidebar v-model="activeKey">
          <van-sidebar-item
            :title="side.name"
            v-for="side in tabList"
            :key="side.id"
            :id="side.id"
            @click="changeSideBar(side.id)"
          />
        </van-sidebar>
      </van-col>
      <van-col span="14">
        <ul class="list">
          <li class="item" v-for="item in videoArr" :key="item.id">
            <div class="item-content" @click="handlePlay(item.url)">
              <!-- <video
                ref="player"
                class="plyr"
                controls
                poster="../img/slt.png"
                @click="handlePlay()"
              >
                <source :src="videoUrl" type="video/mp4" />
              </video> -->
              <van-icon name="play-circle-o" class="playIcon" />
              <img src="../img/ditu.png" alt="" />
            </div>
            <div class="item-title">{{ item.name }}</div>
          </li>
        </ul>
      </van-col>
    </van-row>
    <!-- <van-popup v-model="showVideo" :overlay-style="overlayStyle" class="popup">
      <video ref="player" class="plyr" controls>
        <source :src="videoUrl" type="video/mp4" /></video
    ></van-popup> -->
  </div>
</template>

<script>
// import "plyr/dist/plyr.css"; // 引入 Plyr 样式
// import Plyr from "plyr";
import { getTypeList, getCurrList, getVideoUrl } from "@/api/lesson.js";
export default {
  props: {
    tabListF: {
      type: Array,
      default: [],
    },
    // sidelistF: {
    //   type: Array,
    //   default: [],
    // },
    tabsIdF: {
      type: Number,
      default: 1,
    },
    activeOptionF: {
      type: Number,
      default: 3,
    },
    // activeKey: {
    //   type: Number,
    //   default: 0,
    // },
  },
  data() {
    return {
      // overlayStyle: {
      //   // 遮罩层样式对象
      //   backgroundColor: "rgba(0, 0, 0, 1)",
      // },
      videoUrl: "",
      tabList: [],
      sidelist: [],
      activeOption: 3,
      currType: 0,
      videoArr: [],
      activeKey: 0,
      optionArr: [
        { id: 0, val: "社会工作综合能力" },
        { id: 1, val: "社会工作实物" },
      ],
    };
  },
  created() {
    this.tabList = this.tabListF;
    // this.sidelist = this.sidelistF;
    // this.activeOption = this.activeOptionF;
    this.currType = this.tabList[0].id;
    this.getCurrList();
  },
  watch: {
    // activeOptionF: {
    //   handler(newValue, oldValue) {
    //     console.log(`count 变化了，新值为：${newValue}，旧值为：${oldValue}`);
    //     this.activeOption = newValue;
    //   },
    //   immediate: true, // 首次默认监听
    // },
  },
  methods: {
    //切换option
    // selectTab(tabId) {
    //   this.activeOption = tabId;
    //   // this.activeOptionF = tabId;
    //   this.activeKey=0;
    //   // this.$emit("update:activeOption", this.tabsIdF, tabId);
    //   console.log("activeOption", this.activeOption);
    //   this.getTypeList();
    // },
    //切换sidebar
    changeSideBar(id) {
      console.log("id", id);
      this.currType = id;
      // this.$emit("update:activeKey",this.activeKey);
      this.getCurrList();
    },

    getTypeList() {
      getTypeList({ id: this.activeOption })
        .then((res) => {
          if (res.code === 10000) {
            this.sidelist = res.data;
            this.currType = res.data[0].id;
            this.getCurrList();
            // console.log("this.sidelist", this.sidelist);
            // console.log("this.currType-----------", this.currType);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCurrList() {
      getCurrList({ currType: this.currType })
        .then((res) => {
          if (res.code === 10000) {
            this.videoArr = res.data || [];
          }
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handlePlay(url) {
      getVideoUrl({ url })
        .then((res) => {
          if (res.code === 10000) {
            const url = res.data;
            this.$router.push({
              path: "/video",
              query: {
                url,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  // height: 100vh;
  position: relative;
  // .option {
  //   display: flex;
  //   justify-content: space-around;
  //   height: 0.35rem;
  //   margin: 0.12rem 0;
  //   .tab {
  //     width: 1.4rem;
  //     height: 100%;
  //     line-height: 0.35rem;
  //     background-color: #e9eef2;
  //     border-radius: 1rem;
  //     color: #757575;
  //     font-size: 0.12rem;
  //   }
  //   .active {
  //     color: #183fb7;
  //     border: 1px solid #1e44b9;
  //     background-color: #fff;
  //   }
  // }
  // .option{
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 1.25rem;
  //   height: 100vh;
  //   background-color: #f7f8fa;
  // }
  .row {
    position: relative;
    max-height: calc(100vh - 200px);
    // height: 100%;
    overflow: auto;
    .row-left {
      position: sticky;
      top: 0;
    }
  }
  .list {
    margin-top: 0.2rem;
    .item {
      width: 100%;
      // height: 1.4rem;
      margin-bottom: 0.1rem;
      .item-content {
        position: relative;
        width: 100%;
        height: 1.2rem;
        // background-color: red;
        margin-bottom: 0.08rem;
        .playIcon {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          font-size: .5rem;
          border-radius: 50%;
          color: #fff;
          background-color: rgb(0, 0, 0, 0.4);
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-title {
        text-align: left;
      }
    }
  }
  .van-popup {
    width: 100%;
  }
  .popup {
    .plyr {
      width: 100%;
    }
  }
}
</style>
<style lang="scss" scoped>
.van-sidebar {
  height: calc(100vh - 200px);
  width: 1.25rem;
  position: sticky;
  top: 5rem;
  display: flex;
  flex-direction: column;
}
.van-sidebar-item {
  flex: 1;
  padding: 0 0;
  width: 1.25rem;
  // height: 0.465rem;
  line-height: 0.4rem;
}
.van-sidebar-item--select::before {
  height: 0.25rem;
  background-color: #183fb7;
}
.van-popup {
  background-color: #000;
}
</style>