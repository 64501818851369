<template>
  <div class="video">
    <!-- <video id="myVideo" ref="player" class="plyr" autoplay controls preload="auto">
      <source :src="videoUrl" type="video/mp4" />
    </video> -->
    <div id="dPlayer"></div>
    <!-- <div id="mui-player"></div> -->
  </div>
</template>

<script>
// import "plyr/dist/plyr.css";
// import Plyr from "plyr";

// import 'mui-player/dist/mui-player.min.css'
// import MuiPlayer from 'mui-player'

import DPlayer from "dplayer";

import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      videoUrl: "",
      dp: null,
    };
  },
  created() {
    wx.hideOptionMenu();
    this.videoUrl = this.$route.query.url;
  },
  mounted() {
    //判断用户是否付款
    if (!this.$store.getters["getIfPayed"]) {
      this.$router.replace({ path: "/home" });
    }
    // video播放器
    // const video = document.getElementById("myVideo");
    // // 播放视频
    // video.play();
    // plyr播放器
    // const player = new Plyr(this.$refs.player, {
    //   // 可选的配置选项
    // });
    // DPlayer播放器
    this.dp = new DPlayer({
      container: document.getElementById("dPlayer"),
      video: {
        url: this.videoUrl,
      },
      autoplay: true,
      preload: "auto",
    });

    // var mp = new MuiPlayer({
    //   container: "#mui-player",
    //   title: "标题",
    //   src: this.videoUrl,
    // });
  },
  beforeDestroy() {
    this.dp.destroy();
  },
};
</script>

<style lang="scss" scoped>
.video {
  display: flex;
  align-items: center;
  font-size: 0.14rem;
  background-color: #000;
  height: 100vh;
  .plyr {
    width: 100%;
  }
}
</style>
